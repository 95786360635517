import React, { FC, ReactNode, useCallback } from 'react';
import { combineClassNames } from '../../utils';
import { PropsWithClassName } from '../../interfaces';
import styles from './Button.module.scss';

export interface ButtonProps extends PropsWithClassName {
  type?: 'default' | 'primary' | 'secondary';
  title: string;
  disabled?: boolean;
  onClick?: () => any;
}

export const Button: FC<ButtonProps> = (props) => {
  const { title, type, disabled, onClick, className, children } = props;

  const clickHandler = useCallback(() => {
    if (!disabled && onClick) {
      onClick();
    }
  }, [disabled, onClick]);

  let content: ReactNode;

  if (title) {
    content = <span>{title}</span>;
  } else if (children) {
    content = <>{children}</>;
  }

  return (
    content && (
      <button
        className={combineClassNames(
          className,
          (type === 'primary' && styles.primary) ||
            (type === 'secondary' && styles.secondary) ||
            styles.default,
          disabled && styles.disabled,
          styles.container,
        )}
        disabled={disabled}
        onClick={clickHandler}
      >
        {content}
      </button>
    )
  );
};
