import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app/App';
import './index.scss';

const root = document.getElementById('root');
const element = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

createRoot(root).render(element);
