export function combineClassNames(...classNames: any[]): string {
  return classNames
    .filter((className) => !!className)
    .map(
      (className) =>
        `${
          Array.isArray(className) ? combineClassNames(...className) : className
        }`,
    )
    .join(' ');
}
