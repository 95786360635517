import React, { FC } from 'react';
import { Logo } from '../logo';
import styles from './AppWrapper.module.scss';

const { REACT_APP_ENABLED } = process.env;

export const AppWrapper: FC = (props) => {
  const { children } = props;

  const disabled = !parseInt(REACT_APP_ENABLED) || !children;

  return disabled ? (
    <div className={styles.disabled}>
      <Logo type={'short'} size={80} />
    </div>
  ) : (
    <>{children}</>
  );
};
