import React, { FC, useMemo } from 'react';
import { combineClassNames } from '../../utils';
import { PropsWithClassName } from '../../interfaces';
import { Button, ButtonProps } from '../button';
import styles from './Buttons.module.scss';

export type ButtonsPropsItems = Record<
  string,
  Omit<ButtonProps, 'onClick'> & {
    separator?: string;
  }
>;

export interface ButtonsProps extends PropsWithClassName {
  items?: ButtonsPropsItems;
  onClick?: (button: string) => any;
}

export const Buttons: FC<ButtonsProps> = (props) => {
  const { items, onClick, className } = props;

  const nodes: { key: string; separator?: string; props?: ButtonProps }[] =
    useMemo(() => {
      const result: typeof nodes = [];

      const entries = Object.entries(items);

      for (const [key, { separator, ...props }] of entries) {
        result.push({
          key,
          props: {
            ...props,
            onClick: () => {
              if (onClick) {
                onClick(key);
              }
            },
          },
        });

        if (separator) {
          result.push({
            key: `${key}.separator`,
            separator,
          });
        }
      }

      return result;
    }, [items, onClick]);

  return (
    <div className={combineClassNames(className, styles.container)}>
      {nodes.map(({ key, separator, props }) =>
        separator ? (
          <span key={key}>{separator}</span>
        ) : (
          <Button key={key} {...props} />
        ),
      )}
    </div>
  );
};
