import React, { FC, useMemo } from 'react';
import Color from 'color';
import Twister from 'mersenne-twister';
import { combineClassNames } from '../../utils';
import { PropsWithClassName } from '../../interfaces';
import { COLORS, WOBBLE, SHAPE_COUNT } from './constants';
import styles from './Jazzicon.module.scss';

export interface JazziconProps extends PropsWithClassName {
  size?: number;
  address: string;
}

export const Jazzicon: FC<JazziconProps> = (props) => {
  const { size, address, className } = props;

  const dimensions = useMemo(
    () => ({
      width: size,
      height: size,
    }),
    [size],
  );

  const shapes = useMemo(() => {
    const result: {
      key: string;
      fill: string;
      transform?: string;
    }[] = [];

    if (address) {
      const seed = parseInt(address.toLowerCase().slice(2, 10), 16);

      const generator = new Twister(seed);

      const amount = generator.random() * 30 - WOBBLE / 2;

      const colors = COLORS.map((hex) => new Color(hex).rotate(amount).hex());

      const randomColor = () => {
        generator.random();

        return colors.splice(
          Math.floor(colors.length * generator.random()),
          1,
        )[0];
      };

      result.push({
        key: 'Jazzicon#bg',
        fill: randomColor(),
      });

      const center = size / 2;

      for (let index = 0; index < SHAPE_COUNT; index++) {
        const firstRotation = generator.random();

        const angle = Math.PI * 2 * firstRotation;

        const velocity =
          (size / SHAPE_COUNT) * generator.random() +
          (index * size) / SHAPE_COUNT;

        const translateX = Math.cos(angle) * velocity;
        const translateY = Math.sin(angle) * velocity;

        const secondRotation = generator.random();

        const rotate = (firstRotation * 360 + secondRotation * 180).toFixed(1);

        const fill = randomColor();

        result.push({
          key: `Jazzicon#[${index}]`,
          fill,
          transform: `translate(${translateX} ${translateY}) rotate(${rotate} ${center} ${center})`,
        });
      }
    }

    return result;
  }, [address, size]);

  return (
    <div
      className={combineClassNames(styles.container, className)}
      style={dimensions}
    >
      <svg {...dimensions}>
        {shapes.map(({ key, fill, transform }) => (
          <rect
            key={key}
            fill={fill}
            transform={transform}
            x={0}
            y={0}
            {...dimensions}
          />
        ))}
      </svg>
    </div>
  );
};

Jazzicon.defaultProps = {
  size: 40,
};
